import React from "react";
import server from "../../server";
import { Link, useLocation } from "react-router-dom";
import {
    Box,
    Button,
    Drawer,
    DrawerBody,
    DrawerContent,
    DrawerOverlay,
    Flex,
    Heading,
    HStack,
    Image,
    Menu,
    Spacer,
    Text,
    useDisclosure,
    VStack
} from "@chakra-ui/react";
import { useSettings, useTheme } from "../../context/index";
import { inIframe } from "../../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightToBracket } from "@fortawesome/free-solid-svg-icons";
import { useProfile } from "../../context/user";
import { UserImage } from "../atoms/UserImage";
import { UserEconometer } from "./UserEconometer";
import { MyNotifications } from "./MyNotifications";
import { useAvailableLinks, useTopMenuLinks } from "../../context/links";

function LoginButton() {
    const { primaryColor, primaryFontColor, integratedLogin } = useTheme();

    return (
        <Menu>
            <Link
                style={{
                    width: "100px",
                    marginLeft: "15px",
                    marginRight: "5px"
                }}
                to={integratedLogin ? "/login/parceiro" : "/login"}
            >
                <Button
                    rightIcon={<FontAwesomeIcon icon={faArrowRightToBracket} />}
                    fontSize="14px"
                    letterSpacing="0.04rem"
                    backgroundColor={primaryColor}
                    color={primaryFontColor}
                    _hover={{
                        backgroundColor: primaryColor
                    }}
                    width="100%"
                    to={integratedLogin ? "/login/parceiro" : "/login"}
                    as={Button}
                >
                    LOGIN
                </Button>
            </Link>
        </Menu>
    );
}

const DesktopLinks = ({ primaryColor, fontColor }) => {
    const location = useLocation();
    const { logged } = useProfile();
    const { bookstoreModule, econometerModule, bookstoreType, digitalMagazinesModule } = useTheme();

    const links = useTopMenuLinks();

    return (
        <HStack flex={{ base: 0, lg: 1 }} width="100%">
            <Flex
                marginLeft="auto"
                maxWidth="750px"
                paddingRight="50px"
                display={{ base: "none", lg: "flex" }}
                flexDirection="row"
                flex={{ base: 0, lg: 1 }}
            >
                {links.map((link) => (
                    <Flex
                        key={link.link}
                        height="56px"
                        marginTop="9px"
                        borderBottomWidth="5px"
                        borderStyle="solid"
                        borderColor={
                            location === link.link
                                ? primaryColor
                                : "rgba(0,0,0,0)"
                        }
                        transition="0.2s"
                        _hover={{ borderColor: primaryColor }}
                        align="center"
                        justify="center"
                        flex="1"
                    >
                        <Link
                            style={{
                                color: fontColor,
                                fontWeight: 800,
                                fontFamily: "Montserrat",
                                letterSpacing: "0.04rem",
                                fontSize: 13,
                                flex: 1,
                                textAlign: "center",
                                textDecoration: "none"
                            }}
                            className="link"
                            to={link.link}
                        >
                            <Text>{link.name.toUpperCase()}</Text>
                        </Link>
                    </Flex>
                ))}
                {logged && !bookstoreModule && <Flex
                    height="56px"
                    marginTop="9px"
                    borderStyle="solid"
                    borderBottom="5px solid transparent"
                    transition="0.2s"
                    _hover={{ borderColor: primaryColor }}
                    align="center"
                    justify="center"
                    flex="1"
                >
                    <Link
                        style={{
                            color: fontColor,
                            fontWeight: 800,
                            fontFamily: "Montserrat",
                            letterSpacing: "0.04rem",
                            fontSize: 13,
                            flex: 1,
                            textAlign: "center",
                            textDecoration: "none"
                        }}
                        className="link"
                        to="/minhas-compras"
                    >
                        <Text>MINHAS COMPRAS</Text>
                    </Link>
                </Flex>}
                {logged && bookstoreModule && <Flex
                    height="56px"
                    marginTop="9px"
                    borderStyle="solid"
                    borderBottom="5px solid transparent"
                    transition="0.2s"
                    _hover={{ borderColor: primaryColor }}
                    align="center"
                    justify="center"
                    flex="1"
                >
                    <Link
                        style={{
                            color: fontColor,
                            fontWeight: 800,
                            fontFamily: "Montserrat",
                            letterSpacing: "0.04rem",
                            fontSize: 13,
                            flex: 1,
                            textAlign: "center",
                            textDecoration: "none"
                        }}
                        className="link"
                        to={bookstoreType === 'book' ? '/meus-livros' : '/minhas-revistas'}
                    >
                        <Text>{bookstoreType === 'book' ? 'MEUS LIVROS' : 'MINHAS REVISTAS'}</Text>
                    </Link>
                </Flex>}

                {logged && bookstoreModule && <Flex
                    height="56px"
                    marginTop="9px"
                    borderStyle="solid"
                    borderBottom="5px solid transparent"
                    transition="0.2s"
                    _hover={{ borderColor: primaryColor }}
                    align="center"
                    justify="center"
                    flex="1"
                >
                    <Link
                        style={{
                            color: fontColor,
                            fontWeight: 800,
                            fontFamily: "Montserrat",
                            letterSpacing: "0.04rem",
                            fontSize: 13,
                            flex: 1,
                            textAlign: "center",
                            textDecoration: "none"
                        }}
                        className="link"
                        to={bookstoreType === 'book' ? '/livros-recebidos' : '/revistas-recebidas'}
                    >
                        <Text>{bookstoreType === 'book' ? 'LIVROS RECEBIDOS' : 'REVISTAS RECEBIDAS'}</Text>
                    </Link>
                </Flex>}

              {logged && bookstoreModule && bookstoreType === 'magazine' && digitalMagazinesModule && <Flex
                height="56px"
                marginTop="9px"
                borderStyle="solid"
                borderBottom="5px solid transparent"
                transition="0.2s"
                _hover={{ borderColor: primaryColor }}
                align="center"
                justify="center"
                flex="1"
              >
                <Link
                  style={{
                    color: fontColor,
                    fontWeight: 800,
                    fontFamily: "Montserrat",
                    letterSpacing: "0.04rem",
                    fontSize: 13,
                    flex: 1,
                    textAlign: "center",
                    textDecoration: "none"
                  }}
                  className="link"
                  to={'/revistas-digitais'}
                >
                  <Text>REVISTAS DIGITAIS</Text>
                </Link>
              </Flex>}
            </Flex>
          
            {econometerModule ? <UserEconometer
                titleSize="7.5pt"
                maxWidth={{ base: "0px", md: "200px" }}
                flex={{ base: 0, lg: 1 }}
                forceShow={true}
                hideImage={true}
                showFootNote={false}
                justify="center"
                align="center"
                spacing={1.5}
                barHeight="23px"
                display={{ base: "none", lg: "block" }}
            /> : <></>}
        </HStack>
    );
};

const MobileLinks = ({
    fontColor,
    logout,
    isOpen,
    onClose,
    backgroundColor,
    logo,
    name = "",
}) => {
    const links = useAvailableLinks();
    const { logged } = useProfile();
    const { bookstoreModule, bookstoreType, digitalMagazinesModule } = useTheme();

    return (
        <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
            <DrawerOverlay>
                <DrawerContent>
                    <DrawerBody padding="0px" backgroundColor="#FAFAFA">
                        <Flex
                            height="100px"
                            justifyContent="center"
                            alignItems="center"
                            backgroundColor={backgroundColor}
                        >
                            <Image height="60px" src={logo} />
                        </Flex>
                        <Flex
                            border="1px solid rgb(233, 236, 239)"
                            borderRightWidth="0px"
                            borderLeftWidth="0px"
                            justifyContent="center"
                        >
                            <Heading
                                padding="10px 0px"
                                fontSize="18px"
                                fontWeight="bold"
                                color="rgb(108, 117, 125)"
                            >
                                {name.toUpperCase()}
                            </Heading>
                        </Flex>
                        <Flex direction="column">
                            {links.map((link) => (
                                <Link
                                    key={link.link}
                                    style={{
                                        color: "black"
                                    }}
                                    to={link.link}
                                >
                                    <Button
                                        width="100%"
                                        borderRadius="0px"
                                        leftIcon={
                                            <i
                                                style={{ width: 30 }}
                                                className={link.icon}
                                            />
                                        }
                                        justifyContent="flex-start"
                                        onClick={onClose}
                                    >
                                        <Heading fontSize="13px">
                                            {link.name.toUpperCase()}
                                        </Heading>
                                    </Button>
                                </Link>
                            ))}
                            {logged && !bookstoreModule && <Link
                                style={{
                                    color: "black"
                                }}
                                to="/minhas-compras"
                            >
                                <Button
                                    width="100%"
                                    borderRadius="0px"
                                    leftIcon={
                                        <i
                                            style={{ width: 30 }}
                                            className="fas fa-ticket-alt"
                                        />
                                    }
                                    justifyContent="flex-start"
                                >
                                    <Heading fontSize="13px">
                                        Minhas Compras
                                    </Heading>
                                </Button>
                            </Link>}
                            {logged && bookstoreModule && <Link
                                style={{
                                    color: "black"
                                }}
                                to={bookstoreType === 'book' ? '/meus-livros' : '/minhas-revistas'}
                            >
                                <Button
                                    width="100%"
                                    borderRadius="0px"
                                    leftIcon={
                                        <i
                                            style={{ width: 30 }}
                                            className="fas fa-ticket-alt"
                                        />
                                    }
                                    justifyContent="flex-start"
                                >
                                    <Heading fontSize="13px">
                                        {bookstoreType === 'book' ? 'MEUS LIVROS' : 'MINHAS REVISTAS'}
                                    </Heading>
                                </Button>
                            </Link>}
                            {logged && bookstoreModule && <Link
                                style={{
                                    color: "black"
                                }}
                                to={bookstoreType === 'book' ? '/livros-recebidos' : '/revistas-recebidas'}
                            >
                                <Button
                                    width="100%"
                                    borderRadius="0px"
                                    leftIcon={
                                        <i
                                            style={{ width: 30 }}
                                            className="fas fa-ticket-alt"
                                        />
                                    }
                                    justifyContent="flex-start"
                                >
                                    <Heading fontSize="13px">
                                      {`${bookstoreType === "book" ? "Livros recebidos" : "Revistas recebidas"}`}
                                    </Heading>
                                </Button>
                            </Link>}
                          {logged && bookstoreModule && bookstoreType === 'magazine' && digitalMagazinesModule && <Link
                            style={{
                              color: "black"
                            }}
                            to={'/revistas-digitais'}
                          >
                            <Button
                              width="100%"
                              borderRadius="0px"
                              leftIcon={
                                <i
                                  style={{ width: 30 }}
                                  className="fas fa-ticket-alt"
                                />
                              }
                              justifyContent="flex-start"
                            >
                              <Heading fontSize="13px">
                                {'Revistas Digitais'}
                              </Heading>
                            </Button>
                          </Link>}
                            {server.token ? (
                                <>
                                    <Button
                                        width="100%"
                                        borderRadius="0px"
                                        leftIcon={
                                            <i style={{ width: 30 }} className="fas fa-power-off" />
                                        }
                                        justifyContent="flex-start"
                                        onClick={logout}
                                    >
                                        <Heading fontSize="13px">
                                            Sair
                                        </Heading>
                                    </Button>
                                </>
                            ) : (
                                <></>
                            )}
                        </Flex>
                    </DrawerBody>
                </DrawerContent>
            </DrawerOverlay>
        </Drawer>
    );
};

const DesktopMenu = ({ openModal }) => {
    const { isOpen, onClose, onOpen } = useDisclosure();
    const { logged, resetProfile } = useProfile();
    const {
        id,
        name,
        menuColor,
        menuFontColor,
        logo,
        primaryColor,
        primaryFontColor
    } = useTheme();
    const { isApp } = useSettings();

    const logout = () => {
        server.user = null;
        server.token = null;
        resetProfile()

        try {
            localStorage.removeItem("user");
            localStorage.removeItem("token");
            localStorage.removeItem("auto-login");
        } catch {
            console.warn("Localhost not available");
        }
        window.location.reload();
    };

    if (inIframe() || isApp) return <></>;

    return (
        <>
            <Flex
                backgroundColor={menuColor}
                position="fixed"
                left="0px"
                top="0px"
                width="100%"
                zIndex="901"
                height="65px"
                alignItems="center"
                paddingLeft={{ base: 0, lg: 50 }}
                className="layered-box-light"
                justifyContent={{ base: "space-between", lg: "unset" }}
                paddingRight={{ base: 0, lg: 5 }}
            >
                {/* <Button
                    maxWidth="30px"
                    flex="1"
                    display={{ base: "block", lg: "none" }}
                    marginLeft="8px"
                    onClick={onOpen}
                >
                    <i
                        style={{ color: menuFontColor, fontSize: 24 }}
                        id="bars"
                        className="bars fas fa-bars"
                    />
                </Button> */}
                <Link style={{
                    maxWidth: "200px",
                    flex: "1"
                }} to={{ pathname: "/" }}><Image
                        maxWidth="200px"
                        flex="1"
                        src={logo}
                        height="40px"
                        objectFit="contain"
                        margin={{ base: "auto", lg: "initial" }}
                    /></Link>
                <DesktopLinks
                    primaryColor={primaryColor}
                    fontColor={menuFontColor}
                />
                <MobileLinks
                    backgroundColor={menuColor}
                    logo={logo}
                    name={name}
                    isOpen={isOpen}
                    onClose={onClose}
                    fontColor={menuFontColor}
                    logout={logout}
                />

                {logged ? (
                    <Flex paddingRight="10px">
                        <HStack spacing={5}>
                            <VStack spacing={0}>
                                <MyNotifications />
                            </VStack>
                            <Link className="ProfileLink" to={{ pathname: "/perfil" }}>
                                <UserImage size={50} />
                            </Link>
                        </HStack>


                    </Flex>
                ) : (
                    <LoginButton />
                )}
            </Flex>
            <Spacer height="65px" />
        </>
    );
};

export default DesktopMenu;
