import {
    Button,
    Image,
    useToast,
    VStack,
    Flex,
    Spacer,
    Text,
    Link
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useTheme, useSettings } from "../../context";
import { PasswordInput } from "../../components/atoms/PasswordInput";
import { Title } from "../../components/atoms/Title";
import { Input } from "../../components/atoms/Input";
import { loginSercomtel } from "../../api/sercomtel";
import { useNavigate,Link as LinkRouter } from "react-router-dom";

export function SercomtelLogin() {
    const navigate = useNavigate()

    const { agreement, name, logo, planSaleModule, primaryColor, primaryFontColor, bookstoreModule } = useTheme();
    const toast = useToast();
    const [isLoading, setIsLoading] = useState(false)
    const { isIOS } = useSettings();

    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")

    const submitLogin = async () => {
        try {
            setIsLoading(true)
            const { data: logged } = await loginSercomtel({
                username,
                password
            })
            if (logged.error) throw new Error(logged.error)
            setIsLoading(false)
            const slug = window.location.hostname.split(".")[0]
            localStorage.setItem(
                "auto-login",
                JSON.stringify({ token: logged.token, slug })
            );
            redirect(logged.token);
        } catch (error) {
            setIsLoading(false)
            if (error.response?.status === 403) {
                toast({
                    title: "Erro",
                    description:
                        "Usuário não está habilitado à usar o convênio Sercomtel. Entre em contato com o suporte.",
                    status: "error",
                    duration: 10000
                });
            } else {
                toast({
                    title: "Erro",
                    description: `Status: ${error.response.status} - ${error.response.data}` ,
                    status: "error",
                    duration: 10000
                });
            }
        }
    }

    function redirect(token) {
        window.location.href = `/?token=${token}`;
    }
    useEffect(() => {
        let savedData = localStorage.getItem("auto-login");

        if (!savedData) return;

        try {
            savedData = JSON.parse(savedData);
            if (savedData.slug && savedData.token)
                redirect(savedData.token);
        } catch {
            localStorage.removeItem("auto-login");
        }
    }, []);

    return (
        <VStack
            forcePrimaryColor={agreement?.primaryColor}
            forceSecondaryColor={agreement?.secondaryColor}
            marginTop={isIOS ? "-35px" : "0px"}
            justify="center"
            align="center"
            direction="column"
            margin="auto"
            paddingBottom="100px"
            width={{ base: "100%", lg: "50%" }}
        >
            <Flex justify="center" align="center" width="100%" height="100%" paddingBottom={{ base: "64px", lg: "0" }}>
                <Flex
                    display={{ base: "none", md: "flex" }}
                    flex="1"
                    height="100%"
                    backgroundColor="#FAFAFA"
                    justify="center"
                    align="center"
                >
                    <Image
                        w="300px"
                        objectFit="contain"
                        src={agreement?.logo || logo}
                    />
                </Flex>
                <Flex
                    direction="column"
                    spacing={10}
                    justify="center"
                    width="100%"
                    align="center"
                    minHeight="100%"
                >
                    <Flex justify="center" align="center" minH="250px" flex="1">
                        <Image
                            maxW="300px"
                            // w="30%"
                            objectFit="cover"
                            src={agreement?.logo || logo}
                        />
                    </Flex>
                    <VStack flex="1" width="80%">
                        <VStack
                            align="flex-start"
                            spacing={3}
                            width={{ base: "100%", lg: "60%" }}
                        >
                            <Title paddingBottom="10px">Entre utilizando seu CPF e senha da ÁREA DO CLIENTE da Sercomtel:</Title>
                            <Input
                                value={username}
                                onChange={setUsername}
                                placeholder="CPF ou ID de colaborador"
                                type="text"
                            />
                            <VStack width="100%" spacing={2}>
                                <PasswordInput
                                    pr='4.5rem'
                                    value={password}
                                    onChange={setPassword}
                                    placeholder="Senha da Área do Cliente Sercomtel"
                                />
                              <Link
                                fontSize="10px"
                                alignSelf="end"
                                textDecoration="underline !important"
                                margin="0px"
                                height="48px"
                                lineHeight={"48px"}
                                href="https://aplweb.sercomtel.com.br/areaCliente/formEsqueciSenha.do"
                                target="_blank"
                              >
                                Esqueci minha senha
                              </Link>
                            </VStack>

                            <Spacer />
                            <Button
                                width="100%"
                                isLoading={isLoading}
                                onClick={submitLogin}
                                backgroundColor={primaryColor}
                            >
                                Entrar
                            </Button>
                            {planSaleModule && (
                                <>
                                    <Button backgroundColor={primaryColor} color={primaryFontColor} marginTop={-4} onClick={() => navigate("/signup")} width={"100%"}>
                                        Com Assinatura
                                    </Button>
                                    
                                    <Text paddingTop={"8px"} paddingBottom={"56px"} fontSize={12}>
                                        Cancelou o seu plano com a {name}? Sem problemas{" "}
                                        <LinkRouter
                                            to="/auth"
                                            style={{ textDecoration: "underline", color: primaryColor, fontSize: 13 }}
                                        >clique aqui</LinkRouter>
                                        {bookstoreModule && ', faça seu login e visualize todos os seus livros recebidos.'}
                                    </Text>
                                </>
                            )}
                        </VStack>
                    </VStack>
                </Flex>
            </Flex>
        </VStack>
    );
}
