import { Box, Button, Flex, Heading, Link, ScaleFade, Spinner, Text, VStack } from "@chakra-ui/react";
import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useMemo } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useSettings, useTheme } from "../../context";
import { axiosInstance } from "../../axios";
import { TopMenu } from "../../components/organisms/TopMenu";

export const SubscriptionStatus = () => {   

    const { primaryColor, primaryFontColor, logo } = useTheme();
    const { isMobile } = useSettings();
    const navigate = useNavigate();

    const { id  } = useParams();

    const { isLoading, isError, data } = useQuery('subscription-status', () => axiosInstance.get('subscriptions/' + id + '/'), {
        refetchInterval: 60000,
        retry: 1
    });


    const isSuccess = useMemo(() => {
        if (!data) return false
        return data.data.status === "paid";
    },[data]);


    const getMainContent = useCallback(() => {
        console.log(data)
        if (isLoading || (data && data.data.status === "waiting_payment"))
            return (
                <>
                    <Heading
                        size='lg'
                        marginBottom="24px"
                        maxWidth="500px !important"
                        color="black"
                        textAlign="center"
                        mt={5}
                    >
                        Aguardando confirmação do pagamento do seu plano!
                    </Heading>
                    <Text textAlign="center">
                        Se você já tiver realizado o pagamento, aguarde um instante, pois estamos validando a transação.
                    </Text>
                    {
                        data && data.data && (
                            <Link marginY={"16px"} href={data.data.vindi_url} target="_blank" backgroundColor={"gray.100"} borderRadius={"10px"} padding={"9px"}>
                                Acessar link da compra
                            </Link>
                        )
                    }
                    <Spinner size="xl" />
                </>
            )
        if (isSuccess) 
            return (
                <>
                    <Box marginTop={"24px"}>
                        <ScaleFade initialScale={0.5} in={true}>
                            <FontAwesomeIcon
                                color="#009688"
                                opacity="1"
                                fontSize="48px"
                                icon={faCheckCircle}
                            />
                        </ScaleFade>
                    </Box>
                    <Heading
                        marginBottom="24px"
                        maxWidth="500px !important"
                        fontWeight="500"
                        fontFamily="Open Sans !important"
                        fontSize="32px !important"
                        lineHeight="1.5em"
                        color={'teal.500'}
                        textAlign="center"
                        mt={5}
                    >
                        Compra realizada com sucesso!
                    </Heading>
                    <Text color={'teal.500'} textAlign={"center"}>
                        Parabéns! Sua assinatura foi realizada com sucesso.
                    </Text>
                    <Button color={primaryFontColor} backgroundColor={primaryColor} marginTop={"36px"} onClick={() => navigate("/")}>
                        Ir para a página inicial
                    </Button>
                </>
            )

        if (!isSuccess || isError){
            return (
                <>
                    <Box marginTop={"24px"}>
                        <ScaleFade initialScale={0.5} in={true}>
                            <FontAwesomeIcon
                                color="#F44336"
                                opacity="1"
                                fontSize="48px"
                                icon={faTimesCircle}
                            />
                            
                        </ScaleFade>
                    </Box>
                    <Heading
                        marginBottom="24px"
                        maxWidth="500px !important"
                        fontWeight="500"
                        fontFamily="Open Sans !important"
                        fontSize="32px !important"
                        lineHeight="1.5em"
                        color={'red.500'}
                        textAlign="center"
                        mt={5}
                    >
                        Erro na compra
                    </Heading>
                    <Text color={'red.500'} textAlign={"center"}>
                       Houve um erro ao processar sua compra. Por favor, tente novamente mais tarde.
                    </Text>
                    <Button color={primaryFontColor} backgroundColor={primaryColor} marginTop={"36px"} onClick={() => navigate("/combos")}>
                        Voltar
                    </Button>
                </>
            )
        }
    },[data, isError, isLoading, isSuccess, navigate, primaryColor, primaryFontColor])


    if (isError) return <div>Erro ao buscar os dados do seu plano</div>;

    return (
            <VStack
                alignItems="center"
                flexDirection="column"
                justifyContent="center"
                padding="16px"
                paddingTop="80px"
                boxSizing="border-box"
                paddingBottom={"64px"}
                width="100%"
                height={"100vh"}
            >
                <TopMenu />
                <Box marginBottom={"20px"}>
                    <img width={isMobile ? 150 : 300} height={isMobile ? 150 : 300} src={logo} alt="Logo"></img>
                </Box>
                <Flex
                    width="100%"
                    alignItems="center"
                    justifyContent="center"
                    direction="column"
                    padding={"16px"}
                    flex={1}
                >
                   {getMainContent()}
                </Flex>
            </VStack>
    );
};
