import {
    Button,
    Image,
    useToast,
    VStack,
    Flex,
    Spacer,
    Text
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useTheme, useSettings } from "../../context";
import { Title } from "../../components/atoms/Title";
import { Input } from "../../components/atoms/Input";
import { loginSurf } from "../../api/surf";
import { useNavigate, Link as LinkRouter } from "react-router-dom";

export function SurfLogin() {

    const { agreement, name, logo, planSaleModule, primaryColor, primaryFontColor, bookstoreModule } = useTheme();
    const toast = useToast();
    const [isLoading, setIsLoading] = useState(false)
    const { isIOS } = useSettings();

    const navigate = useNavigate();

    const [username, setUsername] = useState("")

    const submitLogin = async () => {
        try {
            setIsLoading(true)
            const { data: logged } = await loginSurf({
                username,
            })
            if (logged.error) throw new Error(logged.error)
            setIsLoading(false)
            const slug = window.location.hostname.split(".")[0]
            localStorage.setItem(
                "auto-login",
                JSON.stringify({ token: logged.token, slug })
            );

            redirect(logged.token);
        } catch (error) {
            setIsLoading(false)
            if (error.response?.status === 403) {
                toast({
                    title: "Erro",
                    description:
                        "Usuário não está habilitado à usar o convênio Surf. Entre em contato com o suporte.",
                    status: "error",
                    duration: 10000
                });
            } else {
                toast({
                    title: "Erro",
                    description: `Status: ${error.response.status} - ${error.response.data}` ,
                    status: "error",
                    duration: 10000
                });
            }
        }
    }

    function redirect(token) {
        window.location.href = `/?token=${token}`;
    }
    
    useEffect(() => {
        let savedData = localStorage.getItem("auto-login");

        if (!savedData) return;

        try {
            savedData = JSON.parse(savedData);
            if (savedData.slug && savedData.token)
                redirect(savedData.token);
        } catch {
            localStorage.removeItem("auto-login");
        }
    }, []);

    return (
        <VStack
            forcePrimaryColor={agreement?.primaryColor}
            forceSecondaryColor={agreement?.secondaryColor}
            marginTop={isIOS ? "-35px" : "0px"}
            justify="center"
            align="center"
            direction="column"
            margin="auto"
            paddingBottom="100px"
            width={{ base: "100%", lg: "50%" }}
        >
            <Flex justify="center" align="center" width="100%" height="100%">
                <Flex
                    display={{ base: "none", md: "flex" }}
                    flex="1"
                    height="100%"
                    backgroundColor="#FAFAFA"
                    justify="center"
                    align="center"
                >
                    <Image
                        w="300px"
                        objectFit="contain"
                        src={agreement?.logo || logo}
                    />
                </Flex>
                <Flex
                    direction="column"
                    spacing={10}
                    justify="center"
                    width="100%"
                    align="center"
                    minHeight="100%"
                >
                    <Flex justify="center" align="center" minH="250px" flex="1">
                        <Image
                            maxW="300px"
                            // w="30%"
                            objectFit="cover"
                            src={agreement?.logo || logo}
                        />
                    </Flex>
                    <VStack flex="1" width="80%">
                        <VStack
                            align="flex-start"
                            spacing={3}
                            width={{ base: "100%", lg: "60%" }}
                        >
                            <Title paddingBottom="10px">Entre utilizando seu CPF do provedor {name}:</Title>
                            <Input
                              value={username}
                              onChange={setUsername}
                              placeholder="CPF"
                              type="text"
                            />
                            <Spacer />
                            <Button
                                width="100%"
                                isLoading={isLoading}
                                onClick={submitLogin}
                            >
                                Entrar
                            </Button>
                            {planSaleModule && (
                                <>
                                    <Button backgroundColor={primaryColor} color={primaryFontColor} marginTop={-4} onClick={() => navigate("/signup")} width={"100%"}>
                                        Com Assinatura
                                    </Button>
                                    
                                    <Text paddingTop={"8px"} paddingBottom={"56px"} fontSize={12}>
                                        Cancelou o seu plano com a {name}? Sem problemas{" "}
                                        <LinkRouter
                                            to="/auth"
                                            style={{ textDecoration: "underline", color: primaryColor, fontSize: 13 }}
                                        >clique aqui</LinkRouter>
                                        {bookstoreModule && ', faça seu login e visualize todos os seus livros recebidos.'}
                                    </Text>
                                </>
                            )}
                        </VStack>
                    </VStack>
                </Flex>
            </Flex>
        </VStack>
    );
}
