import { Box, Image, Link, Spacer, VStack } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";

function DocumentationLink({ children, visibleElement, href }) {
    const isActive = "#" + visibleElement === href;
    return (
        <Link
            width="100%"
            padding="8px 30px"
            textAlign="left"
            fontWeight="600"
            color={
                isActive ? "white !important" : "rgb(216, 43, 96) !important"
            }
            backgroundColor={isActive ? "rgb(216, 43, 96)" : "transparent"}
            fontSize="16px"
            _hover={{
                backgroundColor: isActive
                    ? "rgb(216, 43, 96)"
                    : "rgba(216, 43, 96,0.1)"
            }}
            href={href}
        >
            {children}
        </Link>
    );
}

function isVisible(elem) {
    const b = elem.getBoundingClientRect();
    return b.top + b.height / 2 >= 0;
}

export function DocumentationMenu() {
    const [visibleElement, setVisibleElement] = useState("api-partiu");

    useEffect(() => {
        const onScroll = (e) => {
            const elements = [
                ...document.getElementsByClassName("documentation-section")
            ];

            const visibleElements = elements.filter((e) => isVisible(e));

            if (visibleElements.length > 0)
                setVisibleElement(visibleElements[0].id);
        };

        window.onscroll = onScroll;
    }, []);

    return (
        <VStack
            position="fixed"
            left="0"
            top="0"
            height="100vh"
            width="250px"
            boxShadow="1px 0 2px #eee"
            align="flex-start"
            margin="0px"
            backgroundColor="white"
            spacing={3}
            paddingTop="20px"
        >
            <Box
                width="100%"
                position="fixed"
                top="0px"
                left="0px"
                height="5px"
                zIndex="100"
                backgroundImage="linear-gradient(90deg, rgb(216, 43, 96), rgb(255, 168, 0))"
            />
            <Image
                paddingLeft="20px"
                width="140px"
                src="https://partiu-static.s3.amazonaws.com/Logos/logo.png"
                paddingBottom="50px"
            />
            <VStack spacing={0} width="100%" align="flex-start">
                <DocumentationLink
                    visibleElement={visibleElement}
                    href="#api-partiu"
                >
                    API Partiu
                </DocumentationLink>
                <DocumentationLink
                    visibleElement={visibleElement}
                    href="#api-authentication"
                >
                    Autenticação
                </DocumentationLink>
                <DocumentationLink
                    visibleElement={visibleElement}
                    href="#api-homologation"
                >
                    Homologação
                </DocumentationLink>
                <DocumentationLink
                    visibleElement={visibleElement}
                    href="#api-verification"
                >
                    API de Verificação
                </DocumentationLink>
                <DocumentationLink
                    visibleElement={visibleElement}
                    href="#api-registration"
                >
                    API de Cadastro
                </DocumentationLink>
                <DocumentationLink
                    visibleElement={visibleElement}
                    href="#api-auto-login"
                >
                    API de Login Automático
                </DocumentationLink>
                <DocumentationLink
                    visibleElement={visibleElement}
                    href="#iframes-webviews"
                >
                    IFrames e Webviews
                </DocumentationLink>
            </VStack>
        </VStack>
    );
}
