import { ChakraProvider, extendTheme, Flex, Heading, Spinner } from "@chakra-ui/react";
import { faMagnifyingGlassDollar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { axiosInstance, axiosInstanceV2 } from "../axios";
import { Documentation } from "../documentation";
import { snakeToCamel } from "../utils";
import { APP_EVENTS, spawnAppEvent } from "../utils/app";
import { storage } from "../utils/storage";
import { useSettings } from "./settings";
import { useProfile } from "./user";

const defaultPartiuTheme = {
    id: null,
    name: "Partiu",
    logo: "",
    primaryColor: "#e53a35",
    primaryFontColor: "white",
    secondaryColor: "#fb8c00",
    secondaryFontColor: "white",
    menuColor: "white",
    menuFontColor: "black",
    canLoginCPF: false,
    price: 0,
    faq: false,
    banners: [],
    welcomeText: null,
    welcomeTextColor: null,
    inAppLanding: true,
    allowCPFLoginMethod: false,
    cpfModule: false,
    allowPromocodeLoginMethod: false,
    bookstoreModule: false,
    planSaleModule: false,
    localModule: false,
    ecommerceModule: true,
    econometerModule: false,
    bePartnerModule: false,
    uraModuleCellphone: "",
    promocodeModule: false,
    defaultBackgroundColor: "#FAFAFA",
    titleColor: "#495057",
    isLoading: true,
    email: "suporte@partiu.com.br",
    bookstoreType: "book",
    setTheme: (theme) => {
    }
};

export function parseTheme(theme) {
    const parsedTheme = {
        bookstoreType: "book"
    };

    Object.entries(theme).forEach(
        ([key, value]) => (parsedTheme[snakeToCamel(key)] = value)
    );

    if (parsedTheme.bookstoreTypes.indexOf("magazine") !== -1) parsedTheme.bookstoreType = "magazine"

    return parsedTheme;
}

export function setBrowserThemeColor(color) {
    document.querySelector("meta[name=\"theme-color\"]").content = color;
}

export function saveTheme(theme) {
    const parsedTheme = parseTheme(theme);

    localStorage.setItem("theme", JSON.stringify(parsedTheme));
    storage.setAgreementId(parsedTheme.id)

    return parsedTheme;
}

export function loadThemeFromSlug(slug) {
    slug = slug.replace(".localhost", "")
    return axiosInstance
        .get("/landings/?slug=" + slug)
        .then(({ data }) => data)
        .then((data) => parseTheme(data));
}

export function loadThemeFromAgreementId(agreementId) {
    return axiosInstance
        .get("/landings/?agreement_id=" + agreementId)
        .then(({ data }) => data)
        .then((data) => parseTheme(data));
}

function setThemeFunctionFactory(setTheme) {
    return function (theme) {
        setTheme({
            ...saveTheme(theme),
            id: theme.id,
            setTheme: setThemeFunctionFactory(setTheme)
        });
    };
}

function getPageSlug(slug) {
    const slugMap = {
        "neo": "Neo",
        "youcast": "YouCast",
        "isuper": "Isuper",
        "voalle": "Voalle",
        "maxx": "Maxx",
        "leste": "Leste Clube",
        "sercomtel": "Sercomtel",
        "clube": "Clube Plis",
    };

    return slugMap[slug] || "Partiu";
}

function verifySlug(slug) {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
        link = document.createElement("link");
        link.rel = "icon";
        document.getElementsByTagName("head")[0].appendChild(link);

    }
    if (slug === "clube") {
        link.href = "https://plis.com.br/wp-content/uploads/2020/12/cropped-cropped-SITE_PLIS-1-32x32.png";
    }
    // document.getElementById("page-title").innerHTML = getPageSlug(slug);
}



const ThemeContext = React.createContext(defaultPartiuTheme);

export function ThemeProvider({ children }) {
    const settings = useSettings();
    const profile = useProfile();
    const [theme, setTheme] = React.useState(defaultPartiuTheme);
    const [loadingScreen, setLoadingScreen] = React.useState(true);
    const loadingScreenRef = React.useRef(null);

    function processTheme(rawTheme, slug) {
        saveTheme(rawTheme);
        document.title = rawTheme.name
        setTheme({
            ...rawTheme,
            isLoading: false,
            setTheme: setThemeFunctionFactory(setTheme)
        });
        verifySlug(slug, rawTheme.name);
        axiosInstanceV2.get('promotional/banners').then((response) => {
            setTheme({
                ...rawTheme,
                isLoading: false,
                setTheme: setThemeFunctionFactory(setTheme),
                banners: rawTheme.banners && rawTheme.banners.length > 0 ? rawTheme.banners : response.data
            });
            verifySlug(slug);
        });

    }

    useEffect(() => {

        let slug;
        if (window.location.hostname.includes('partiu.com.br')){
            slug = window.location.hostname.split(".")[0];
        } else {
            slug = window.location.hostname
        }
        
        if (slug === "viuvantagens") slug = "viu";
        else if (slug === "localhost" || slug === "b28") slug = "app";
        let themePromise = loadThemeFromSlug(slug);


        themePromise.then((theme) => {
            processTheme(theme, slug);
        }).catch(() => loadThemeFromSlug('app').then(theme => processTheme(theme, 'app')));
    }, [settings]);

    useEffect(() => {
        // Extending Chakra theme

        // Laading screen fading animation
        let timeout;

        setBrowserThemeColor(theme.primaryColor);

        if (!theme.isLoading && loadingScreenRef.current) {
            timeout = setTimeout(() => {
                if (loadingScreenRef.current) loadingScreenRef.current.classList.add("animate__fadeOut");
                setBrowserThemeColor(theme.menuColor);
                setTimeout(() => {
                    if (loadingScreenRef.current)  loadingScreenRef.current.classList.remove(
                        "animate__fadeOut"
                    );
                    setBrowserThemeColor(theme.menuColor);
                    spawnAppEvent(APP_EVENTS.START);
                    setLoadingScreen(false);
                }, 1000);
            }, 1500);
        }

        return () => clearTimeout(timeout);
    }, [theme]);

    if (theme.name === "developer") {
        return <Documentation />;
    }

    return (
        <ChakraProvider
            theme={extendTheme({
                colors: {
                    primary: theme.primaryColor,
                    secondary: theme.secondaryColor,
                    primaryFont: theme.primaryFontColor,
                    primaryScheme: {
                        600: theme.primaryColor
                    }
                },
                components: {
                    Button: {
                        baseStyle: {
                            fontFamily: "Montserrat",
                            textTransform: "uppercase",
                            fontSize: 3
                        },
                        sizes: {
                            md: {
                              minHeight: "48px",
                              minWidth: "48px",
                            }
                        },
                        variants: {
                            solid: {
                                fontSize: "11pt"
                            },
                            outline: {
                                borderWidth: "2px",
                                fontSize: "11pt",
                                letterSpacing: "0.03rem",
                                borderColor: theme.primaryColor,
                                color: theme.primaryColor
                            }
                        }
                    },
                    Input: {
                      sizes: {
                        md: {
                          field: {
                            height: "48px",
                          }
                        }
                      },
                    },
                    ModalCloseButton: {
                      sizes: {
                        md: {
                          height: "48px",
                        }
                      },
                    }
                }
            })}
            colorModeManager={() => {
            }}
        >
            <ThemeContext.Provider value={theme}>
                {loadingScreen || profile === "loading" ? (
                    <Flex
                        position="fixed"
                        left="0px"
                        top="0px"
                        width="100%"
                        height="100%"
                        className="animate__animated"
                        direction="column"
                        justify="center"
                        backgroundColor={theme.primaryColor}
                        align="center"
                        gap={10}
                        zIndex="1000"
                        color={`${theme.primaryFontColor}!important`}
                        ref={loadingScreenRef}
                        transition="2s"
                        textAlign="center"
                    >
                        <FontAwesomeIcon
                            size="5x"
                            icon={faMagnifyingGlassDollar}
                        />
                        <Heading alignSelf="center" fontSize="20pt">
                            Carregando {theme.bookstoreModule ? theme.bookstoreType === "book" ? "sua Biblioteca Virtual" : "sua Banca Virtual" :'seu Clube de Vantagens!'}
                        </Heading>
                        <Spinner thickness="10px" opacity="0.8" size="xl" />
                    </Flex>
                ) : (
                    <></>
                )}
                {profile ? <>{children}</> : <></>}
            </ThemeContext.Provider>
        </ChakraProvider>
    );
}

export function useTheme() {
    return React.useContext(ThemeContext);
}
