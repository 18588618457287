import {
  Link,
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useToast,
  VStack
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useMutation } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { login as loginFunc } from "../../../api/login";
import { Button } from "../../../components/atoms/Button";
import { Input } from "../../../components/atoms/Input";
import { PasswordInput } from "../../../components/atoms/PasswordInput";
import { RFHMaskedInput } from "../../../components/atoms/RFHMaskedInput";
import { Title } from "../../../components/atoms/Title";
import { useTheme } from "../../../context";
import { useLogin } from "../context/login.context";

export function Login() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [username, setUsername] = useState();
  const { login } = useLogin();
  const [password, setPassword] = useState();
  const { id, bookstoreType, bookstoreModule } = useTheme();
  const toast = useToast();

  const loginMutation = useMutation(() => loginFunc(id, username, password), {
    onSuccess({ data, status }) {
      if (status === 400)
        return toast({
          title: "Oops!",
          description: data.error,
          status: "error"
        });
      login(data.token, data.user);
      if (searchParams.get("redirectUrl")) {
        navigate(searchParams.get("redirectUrl"));
      } else {
        navigate("/");
      }
    },
    onError() {
      toast({
        title: "Oops!",
        description: "Esta combinação de email/cpf e senha não existe! Verifique seus dados e tente novamente",
        status: "error"
      });
    }
  });

  return (
    <VStack
      align="flex-start"
      spacing={7}
      width={{ base: "100%", lg: "80%" }}
    >
      <Title paddingBottom="10px">Entre com a sua conta:</Title>
      <Tabs colorScheme="primaryScheme" isFitted width="100%">
        <TabList>
          <Tab height="48px">CPF</Tab>
          <Tab height="48px">CNPJ</Tab>
          <Tab height="48px">Email</Tab>
        </TabList>
        <TabPanels paddingTop="30px">
          <TabPanel padding="0px">
            <RFHMaskedInput
              value={username}
              onChange={(e) =>
                setUsername(
                  e.target.value
                    .replace(/\./g, "")
                    .replace("-", "")
                )
              }
              placeholder="CPF"
              mask="999.999.999-99"
            />
          </TabPanel>
          <TabPanel padding="0px">
            <RFHMaskedInput
              value={username}
              onChange={(e) =>
                setUsername(
                  e.target.value
                    .replace(/\./g, "")
                    .replace("-", "")
                    .replace("/", "")
                )
              }
              placeholder="CNPJ"
              mask="99.999.999/0001-99"
            />
          </TabPanel>
          <TabPanel padding="0px">
            <Input
              value={username}
              onChange={setUsername}
              placeholder="Email"
              type="email"
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
      <VStack width="100%" spacing={2}>
        <PasswordInput
          pr="4.5rem"
          value={password}
          onChange={setPassword}
          placeholder="Senha"
        />
        <Link
          fontSize="10px"
          alignSelf="end"
          textDecoration="underline !important"
          margin="0px"
          href={`${process.env.REACT_APP_API_URL}/password_reset/?agreement_id=${id}`}
          target="_blank"
          height={"48px"}
        >
          Esqueci minha senha
        </Link>
      </VStack>
      <Spacer />
      <Button
        width="100%"
        isLoading={loginMutation.isLoading}
        onClick={loginMutation.mutate}
      >
        Entrar
      </Button>
      <Link
        href="#"
        fontSize="14px"
        alignSelf="center"
        textDecoration="underline !important"
        onClick={() => navigate("/login")}
      >
        Não tenho uma conta
      </Link>
      {bookstoreModule && bookstoreType === "book" && (
        <Text paddingTop={"8px"} paddingBottom={"56px"} fontSize={12}>
          Mesmo cancelando o seu plano, você ainda pode acessar os livros recebidos anteriormente. Basta fazer login acima.
        </Text>
      )}
      {bookstoreModule && bookstoreType === "magazine" && (
        <Text paddingTop={"8px"} paddingBottom={"56px"} fontSize={12}>
          Mesmo cancelando o seu plano, você ainda pode acessar as suas revistas recebidas anteriormente. Basta fazer login acima.
        </Text>
      )}
    </VStack>
  );
}
