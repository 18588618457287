import React from "react";
import { Heading, VStack } from "@chakra-ui/layout";
import { Stack } from "@chakra-ui/react";
import { useSettings, useTheme } from "../../context/index";
import { Title } from "../../components/atoms/Title";
import Introduction from "./components/Introduction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping, faFileText } from "@fortawesome/free-solid-svg-icons";

import { useNavigate } from "react-router-dom";

export const BePartner = () => {
    const { name, primaryColor, secondaryColor, titleColor } = useTheme();
    const { isApp } = useSettings();
    const navigate = useNavigate();

    return (
        <VStack width="100%" spacing={0}>
            <Stack
                alignItems="flex-start"
                justifyContent="flexstart"
                spacing={{ base: 10, lg: 40 }}
                width="100%"
                padding="60px 5%"
                paddingTop={isApp ? "80px" : "60px"}
                transform={isApp ? "translateY(-50px)" : ""}
                background={
                    "linear-gradient(135deg, " +
                    secondaryColor +
                    " 0%," +
                    primaryColor +
                    " 100%)"
                }
                direction={{ base: "column", lg: "row" }}
            >
                <VStack
                    alignItems="flex-start"
                    spacing={5}
                    textAlign={{ base: "left", lg: "left" }}
                >
                    <Title
                        fontSize="24pt !important"
                        color="white"
                        textAlign="left"
                    >
                        Clube de Vantagens {name}
                    </Title>
                    <Heading fontWeight="300" fontSize="18pt" color="white" whiteSpace={'break-spaces'}>
                        {`Que tal cadastrar o seu estabelecimento como parceiro do nosso Clube de Vantagens?
Seja divulgado para toda a nossa base e aumente as suas vendas.

É gratuito para sua loja. Nenhum custo para se cadastrar, basta você disponibilizar um desconto REAL para o cliente final.`}
                    </Heading>
                </VStack>
                <VStack 
                    onClick={() => navigate({ pathname: "/formulario-cadastro" })}
                    id="cadastre-se"
                    color={titleColor}
                    backgroundColor="#FAFAFA"
                    padding="28px"
                    borderRadius="5px"
                    transition="0.2s"
                    cursor={"pointer"}
                    whiteSpace={'nowrap'}
                    boxShadow="0px 3px 6px rgba(0, 0, 0, 0.20)">
                    <FontAwesomeIcon color={primaryColor} size="3x" icon={faFileText} alignmentBaseline="middle" />
                    <Title fontWeight="700" paddingTop="10px">
                        Cadastre-se agora!
                    </Title>
                </VStack>
            </Stack>
            <Introduction />
        </VStack>
    );
};
