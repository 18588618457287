import React from "react";
import { Link, Spacer, Text, VStack } from "@chakra-ui/react";
import { useQuery } from "react-query";
import { Button } from "../../../components/atoms/Button";
import { Title } from "../../../components/atoms/Title";
import { Navigate, useNavigate } from "react-router-dom";
import { useLogin } from "../context/login.context";
import { LoginWrapper } from "./LoginWrapper";
import { listSubscriptionByCpf } from "../../../api/subscription";
import { sanitizeCpfOrCnpj } from "../../../utils";

export function ConfirmAgreement() {
    const navigate = useNavigate()

    const { validatedAgreement, validatedDocument ,setValidatedDocument, setValidatedAgreement, setValidatedPromocode, validatedPromocode} = useLogin();

    const getPlanQuery = useQuery(['listSubscriptionByCpf', validatedDocument], () => listSubscriptionByCpf(sanitizeCpfOrCnpj(validatedDocument)), {
      enabled: !!validatedDocument,
      refetchOnMount: true,
    });

    const getPlanContent = () => {

      if(validatedPromocode){
        return (
          <>
            <Text>
              Para aproveitar todas as vantagens do Clube, continue o cadastro.
            </Text>
            <Button
              marginTop="auto"
              width="100%"
              backgroundColor={validatedAgreement.secondaryColor}
              color={validatedAgreement.secondaryFontColor}
              _hover={{
                  backgroundColor: validatedAgreement.secondaryColor
              }}
              onClick={() => navigate('/signup')}
            >
              Continuar cadastro
            </Button>
            <Link
                color="white"
                href="#"
                fontSize="14px"
                textDecoration="underline !important"
                onClick={() => {
                  setValidatedDocument(null);
                  setValidatedAgreement(null);
                  setValidatedPromocode(null);
                  navigate('/login')
                }}
            >
                Cancelar
            </Link>
          </>
        );
      }

      if (getPlanQuery.isLoading) {
        return <Text>Carregando...</Text>;
      }
      if (getPlanQuery.isError) {
        return <Text>Ocorreu um erro ao consultar o plano do seu cpf/cnpj</Text>;
      }
      if (getPlanQuery.isSuccess) {
        const { data } = getPlanQuery;
        if (data.length === 0 || !data[0].plan) {
          return <Text>Nenhum plano encontrado</Text>;
        }

        return (
          <>
            <VStack>
              <Text>Você tem acesso ao plano:</Text>
              <Title fontSize="28px">{data[0].plan.name}</Title>
            </VStack>
            {
              data[0].user ? (
                <>
                  <Text>
                    Notamos que já existe um cadastro para esse CPF na nossa base. 
                    <br/>
                    Faça seu login agora mesmo,
                    para aproveitar todas as vantagens do Clube.
                  </Text>
                  <Button
                    marginTop="auto"
                    width="100%"
                    backgroundColor={validatedAgreement.secondaryColor}
                    color={validatedAgreement.secondaryFontColor}
                    _hover={{
                        backgroundColor: validatedAgreement.secondaryColor
                    }}
                    onClick={() => navigate('/login/auth')}
                  >
                    Fazer login
                  </Button>
                </>
              ) : (
                <>
                <Text>
                    Para aproveitar todas as vantagens do Clube, continue o cadastro.
                  </Text>
                <Button
                  marginTop="auto"
                  width="100%"
                  backgroundColor={validatedAgreement.secondaryColor}
                  color={validatedAgreement.secondaryFontColor}
                  _hover={{
                      backgroundColor: validatedAgreement.secondaryColor
                  }}
                  onClick={() => navigate('/signup')}
                >
                  Continuar cadastro
                </Button>
                </>
              )
            }
            
            <Link
                color="white"
                href="#"
                fontSize="14px"
                textDecoration="underline !important"
                onClick={() => {
                  setValidatedDocument(null);
                  setValidatedAgreement(null);
                  setValidatedPromocode(null);
                  navigate('/login')
                }}
            >
                Cancelar
            </Link>
          </>
        );
      }
    };

    if (!validatedAgreement) {
        return(<Navigate to="/login" replace={true} />);
    }

    return (
        <LoginWrapper>
            <VStack width={{ base: "100%", lg: "60%" }} height="100%" spacing={7}>
                <Title fontSize="28px">{validatedAgreement.name}!</Title>
                <Text textAlign="justify">
                    Parabéns! Este código pertence ao {validatedAgreement.name}.
                </Text>
                <Spacer />
                {getPlanContent()}
            </VStack>
        </LoginWrapper>
    );
}
