import { HStack, VStack, useDisclosure } from "@chakra-ui/react";
import {
    faCreditCard,
    faInfoCircle,
    faLock,
    faMessage,
    faQuestionCircle,
    faRightFromBracket,
    faStore,
    faTicket,
    faMoneyBill,
    faTicketAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import { Title } from "../../../components/atoms/Title";
import { useTheme } from "../../../context";
import { AddAgreementButton } from "./AddAgreement";
import { MyPucharsesDrawer } from "../../../components/organisms/MyPurchasesDrawer";
import { useProfile } from "../../../context/user";

export function MenuLink({
    icon, children, to = "#", onClick = () => {
    }
}) {
    const { titleColor } = useTheme();
    return (
        <Link
            style={{
                width: "100%",
                padding: "0px",
                paddingTop: "10px",
                textDecoration: "none"
            }}
            onClick={onClick}
            to={to}
        >
            <HStack
                paddingTop="10px"
                paddingBottom="20px"
                spacing={5}
                width="100%"
            >
                <FontAwesomeIcon
                    style={{ width: "30px" }}
                    size="lg"
                    icon={icon}
                />
                <Title fontWeight="700" fontSize="12pt">
                    {children}
                </Title>
            </HStack>
            <hr />
        </Link>
    );
}

export function Menu() {
    const {resetProfile} = useProfile();
    const { bePartnerModule, promocodeModule, localModule, ecommerceModule, bookstoreModule, bookstoreType, econometerModule, digitalMagazinesModule } = useTheme();
    return (
        <VStack spacing={0} width={{ base: "100%", lg: "92%" }}>
            {promocodeModule ? <AddAgreementButton>
                <MenuLink to="#" icon={faTicket}>
                    Tenho um Código Promocional
                </MenuLink>
            </AddAgreementButton> : <></>}
            {bePartnerModule ? <MenuLink to="/cadastrar-loja" icon={faStore}>
                Seja Parceiro
            </MenuLink> : <></>}
            {econometerModule ? <MenuLink to="/econometro" icon={faMoneyBill}>
                Econômetro
            </MenuLink> : <></>}
            {!bookstoreModule && <MenuLink to="/minhas-compras" icon={faTicketAlt}>
                Minhas Compras
            </MenuLink>}
            {bookstoreModule && <MenuLink to={bookstoreType === "book" ? '/biblioteca' : '/banca'} icon={faTicketAlt}>
                {bookstoreType === "book" ? 'Minha Biblioteca' : 'Minha Banca'}
            </MenuLink>}
            {bookstoreModule && <MenuLink to={bookstoreType === 'book' ? '/livros-recebidos' : '/revistas-recebidas'} icon={faTicketAlt}>
                {`${bookstoreType === "book" ? "Livros Recebidos" : "Revistas Recebidas"}`}
            </MenuLink>}
            {bookstoreModule && bookstoreType === 'magazine' && digitalMagazinesModule && <MenuLink to={'/revistas-digitais'} icon={faTicketAlt}>
              Revistas Digitais
            </MenuLink>}
            <MenuLink to="/combos" icon={faCreditCard}>
                Combos (Upgrade ou Contrate)
            </MenuLink>
            <MenuLink to="/contato" icon={faMessage}>
                Fale Conosco
            </MenuLink>
            {localModule || ecommerceModule && <MenuLink to="/como-usar" icon={faQuestionCircle}>
                Como Usar
            </MenuLink>}
            {localModule || ecommerceModule ? <MenuLink to="/sobre-o-clube" icon={faInfoCircle}>
                Sobre o Clube
            </MenuLink> : <></>}
            <MenuLink to="/legal/politica-de-privacidade" icon={faLock}>
                Politicas de Privacidade
            </MenuLink>
            <MenuLink
                icon={faRightFromBracket}
                onClick={() => {
                    localStorage.removeItem("theme");
                    localStorage.removeItem("token");
                    localStorage.removeItem("auto-login");
                    localStorage.removeItem("appAgreementData");

                    resetProfile();
                    window.location.reload();
                }}
            >
                Sair
            </MenuLink>
        </VStack>
    );
}
